<template>
  <div class="root">
    <div>
      Source:
      <Multiselect v-model="localItem.source" :options="imageSources" @change="onChange" />
    </div>
    <div>
      <div v-if="localItem.source === 'file'" class="file">
        <FileSelect accept=".png,.jpeg,.jpg,.svg" :multiple="true" variant="contained" @change="imageSelected">
          <MdIcon size="md" name="file-word" style="margin-right: 3px" />Select Image(s)
        </FileSelect>
        <div class="preview">
          <div v-for="file in localItem.value" :key="file.name">
            <div class="image">
              <div class="thumbnail" :class="{ svg: file.name.endsWith('svg') }">
                <img :src="file.base64" :alt="file.name" />
              </div>
              <Button class="button" title="Remove" @click="clear(file)">
                <MdIcon size="md" name="close" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        NOT IMPLEMENTED
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Multiselect from '@/components/common/Multiselect';
import FileSelect from '@/components/common/FileSelect';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    Multiselect,
    FileSelect,
    Button,
    MdIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localItem: {
        ...this.item
      }
    };
  },
  computed: {
    ...mapState({
      imageSources: s => s.nlp.imageSources
    })
  },
  methods: {
    onChange() {
      this.$emit('change', { ...this.localItem });
    },
    async imageSelected(files) {
      const loadedFiles = [];
      for (const file of files) {
        if (/(jpg)|(jpeg)|(png)|(svg)$/.test(file.name)) {
          this.progress = `Loading ${file.name}...`;
          const image = await new Promise(async function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = () => {
              resolve({
                name: file.name,
                base64: reader.result
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          loadedFiles.push(image);
        }
      }

      this.localItem.value = loadedFiles;

      this.onChange();
    },
    clear(file) {
      if (Array.isArray(this.localItem.value)) {
        const index = this.localItem.value.indexOf(file);
        this.localItem.value.splice(index, 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  display: grid;
  gap: 5px;
  grid-template-rows: auto auto 1fr;

  .file {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 5px;
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 2px;

    .preview {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      div:not(:last-child) {
        margin-bottom: 1px;
      }

      .thumbnail {
        position: relative;
        z-index: 0;
        padding: 2px;

        &.svg {
            background-color: white;
        }

        img {
          max-height: 75px;
        }
      }

      .image {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 2px;
        .button {
          position: absolute;
          visibility: hidden;
          right: 0;
          padding: 0;
          z-index: 1000;
          cursor: pointer;
          color: var(--theme-on-surface);
          border-radius: 2px;
        }
      }
      .image:hover .button {
        visibility: visible;
      }
    }
  }
}
</style>
