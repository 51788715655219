<template>
  <section v-if="isGetRequestPending" class="edit-user">
    <p-loading />
  </section>
  <section v-else-if="!item" class="edit-user">
    <p-not-found />
  </section>
  <section v-else class="edit-user">
    <header>
      <h1>Edit User</h1>
      <div>
        <router-link :to="`/people/${$route.params.id}/change-password`">change password</router-link>
        |
        <router-link :to="`/people/${$route.params.id}/permissions`">manage permissions</router-link>
        |
        <router-link :to="`/people/${$route.params.id}/authorization`">manage authorization</router-link>
        |
        <router-link :to="`/people/${$route.params.id}/user-settings`">manage user settings</router-link>
        <div class="action-wrapper">
          <p-button v-if="$hasPermission('users.write')" color="secondary" :disabled="item.email === identity.email" @click="confirmDelete"
            >Delete</p-button
          >
        </div>
      </div>
      <div></div>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="email" :label="'Email'" autocomplete="off" :disabled="true" />
      </div>
      <div class="form-row">
        <p-text-field v-model="firstName" :label="'First name'" autocomplete="off" :disabled="!$hasPermission('users.write')" />
        <div v-if="!$v.firstName.required" class="error">First name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="lastName" :label="'Last name'" autocomplete="off" :disabled="!$hasPermission('users.write')" />
        <div v-if="!$v.lastName.required" class="error">Last name is required</div>
      </div>
      <div class="form-row">
        <p-tags v-model="assistants" :disabled="!$hasPermission('users.write')" label="Assistants" placeholder="" :options="suggestMember" />
      </div>
      <div class="form-row">
        <p-checkbox v-model="isBot" label="Is Bot" @change="handleScriptChange"></p-checkbox>
        <span
          v-if="foundScriptMessage || foundVersionMessage"
          class="tooltip-icon"
          @mouseenter="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <p-md-icon name="information-outline" color="warning" size="sm" />
          <div v-if="showTooltip" class="tooltip">
            <div v-if="foundScriptMessage">{{ foundScriptMessage }}</div>
          </div>
        </span>
      </div>
      <div v-if="isBot" class="form-row">
      <div v-if="isScriptsLoading" class="loading-pane">
          <p>Loading scripts...</p>
        </div>
        <p-multiselect
          v-model="script"
          :options="scripts"
          :label="'Script'"
          :searchable="true"
          placeholder="Select a script"
          :disabled="isScriptsLoading"
        />
      </div>
      <div v-if="isBot && script" class="form-row">
      <div v-if="isVersionsLoading" class="loading-pane">
          <p>Loading versions...</p>
        </div>
        <p-version-multiselect
          v-model="version"
          :label="'Version'"
          :options="versions"
          placeholder="Select a version"
          :disabled="isVersionsLoading"
        />
      </div>
      <div v-if="isUpdateRequestFailed" class="error">
        Failed to update a user. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button
          v-if="$hasPermission('users.write')"
          color="primary"
          type="submit"
          :disabled="isUpdateRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Update</p-button
        >
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';
import { required } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import NotFound from '@/components/NotFound';
import CheckBox from '@/components/common/Checkbox.vue';
import Tags from '@/components/common/Tags';
import Multiselect from '@/components/common/Multiselect';
import VersionMultiselect from '@/components/common/VersionMultiselect';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-button': Button,
    'p-not-found': NotFound,
    'p-checkbox': CheckBox,
    'p-tags': Tags,
    'p-multiselect': Multiselect,
    'p-version-multiselect': VersionMultiselect,
    'p-md-icon': MdIcon
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      isBot: false,
      assistants: [],
      scripts: [],
      script: null,
      versions: [],
      version: null,
      isVersionsLoading: false,
      isScriptsLoading: false,
      foundScriptMessage: '',
      showTooltip: false,
    };
  },
  computed: {
    ...mapState({
      identity: s => s.identity,
      item: s => s.users.item,
      isGetRequestPending: s => s.users.isGetRequestPending,
      isUpdateRequestPending: s => s.users.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.users.isUpdateRequestFailed,
      isDeleteRequestPending: s => s.users.isDeleteRequestPending,
      isDeleteRequestFailed: s => s.users.isDeleteRequestFailed,
      practitioners: s => s.portfolio.practitioners.collection,
      assistantTeam: s => s.teams.assistantsTeam
    }),
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.$store.dispatch('users/getById', this.$route.params.id);
        await this.$store.dispatch('teams/getAssistants', this.item.email);

        this.email = this.item && this.item.email;
        this.firstName = this.item && this.item.firstName;
        this.lastName = this.item && this.item.lastName;
        this.isBot = this.item && this.item.isBot;
        this.assistants = this.assistantTeam ? [...this.assistantTeam.members] : [];

        if (this.isBot) {
          await this.handleScriptChange()
          this.script = this.item?.scriptKey?.split('/')[0];
          if(!this.script) {
            const candidateScript = this.scripts?.find(s => s === this.email?.split('@')[0]);
            if (candidateScript) {
              this.script = candidateScript;
              await this.getVersion(this.script);

              this.version = this.versions[0];
              this.foundScriptMessage = `We found "${candidateScript}" as a script candidate based on the email. Click update to save it.`;
            }  else {
              this.foundScriptMessage = '';
            }
            return;
          }
          await this.getVersion(this.script);
          this.version = this.versions?.find(v => v.Key === this.item?.scriptKey);

        }
      },
      immediate: true
    },
    script(newScript) {
      if (newScript) {
        this.version = null;
        this.versions = [];
        this.getVersion(newScript);
      }
    },
    isBot(value) {
      if (!value) {
        this.script = null;
        this.version = null;
        this.foundScriptMessage = '';
      }
    }
  },
  async created() {
    await this.$store.dispatch('portfolio/practitioners/getCollection');
  },
  methods: {
    async getVersion(script) {
      this.isVersionsLoading = true;
      const versions = await httpClient.get(`/api/auth/bots/${script}/versions`);
      this.versions = versions
        .filter(v => v.Key.endsWith('js'));
      this.isVersionsLoading = false;
    },
    async handleScriptChange() {
      if (this.isBot) {
        this.isScriptsLoading = true;
        try {
          this.scripts = await httpClient.get('/api/auth/bots/bot/scripts');
        } finally {
          this.isScriptsLoading = false;
        }
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        await this.$store.dispatch('users/update', {
          id: this.$route.params.id,
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          isBot: this.isBot,
          assistants: this.assistants,
          scriptKey: this.isBot ? this.version?.Key || null : null
        });
        if (!this.assistantTeam) {
          const team = await this.$store.dispatch('teams/create', {
            name: `${this.item.email.split('@')[0]} assistants`,
            members: this.assistants,
            assistantsTeam: true
          });
          await this.handleAsistantsChange(team.id);
        } else {
          await this.handleAsistantsChange(this.assistantTeam.id);
        }

      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Update failed',
          message: message || `Please, try again later or contact our development team.`
        });
      } finally {
        if (!this.isUpdateRequestFailed) {
          this.$router.push({ path: `/people` });
        }
      }
    },
    async handleAsistantsChange(teamId) {
      const adddedAssistants = this.assistants.filter(a => !this.assistantTeam.members.includes(a));
      const removedAssistants = this.assistantTeam.members.filter(a => !this.assistants.includes(a));
      const addPromises = adddedAssistants.map(email =>
        this.$store.dispatch('teams/addMember', {
          teamId,
          email
        })
      );
      const removePromises = removedAssistants.map(email =>
        this.$store.dispatch('teams/removeMember', {
          teamId,
          email
        })
      );
      await Promise.all([...addPromises, ...removePromises]);
    },
    back() {
      this.$router.back();
    },
    async suggestMember(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    },
    async confirmDelete() {
      const { email, firstName, lastName } = this.item;
      const fullName = [firstName, lastName].join(' ');

      const confirmResult = await this.$confirm({
        title: 'Delete user?',
        message: `Are you sure you want to delete ${fullName} (${this.item.email})? This action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();

      await this.$store.dispatch('users/delete', this.$route.params.id);

      lock.release();

      if (this.isDeleteRequestFailed) {
        this.$toast.error({
          title: 'Failed to delete user',
          message: `Please, try again later or contact our development team.`
        });
      } else {
        this.$toast.success({
          title: 'User deleted',
          message: `User ${fullName} (${email}) was successfully deleted.`
        });
        this.$router.push({ path: `/people` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-user {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
    position: relative;

    a {
      font-size: 0.8rem;
    }

    .action-wrapper {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .loading-pane {
      text-align: center;
      padding: 10px;
      font-size: 0.85rem;
      font-weight: bold;
      color: var(--theme-primary);
    }

  .bot-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    height: 40px;

    .script-buttons {
      display: flex;
      justify-content: flex-end;

      .newScript {
        background: var(--theme-highlight);
        border-radius: 5px;
      }

      form {
        padding: 0;
      }
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  span {
    margin: 0 5px 0;
  }

  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }

    .tooltip-icon {
      margin-left: 8px;
      cursor: pointer;
      font-size: 1rem;
      position: relative;
    }

    .tooltip {
      position: absolute;
      top: -1.5rem;
      left: 0;
      background: var(--theme-warning);
      color: var(--theme-on-background);
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 0.85rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      white-space: nowrap;
      z-index: 100;
    }
  }
}
</style>
