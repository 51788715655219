<template>
  <div class="multiselect-wrapper">
    <multiselect
      :id="id"
      :value="value"
      :options="options"
      :multiple="false"
      :searchable="true"
      :taggable="false"
      :show-no-results="false"
      :hide-selected="true"
      :show-labels="false"
      :show-no-options="false"
      :custom-label="getLabelCallback"
      :disabled="false"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <span v-if="option">
          {{ getLabel(option) }} 
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div v-if="props.option" class="option-item">
          {{ getLabel(props.option) }}
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    getLabelCallback: {
      type: Function,
      default: option => option?.Key || 'N/A'
    }
  },
  methods: {
    getVersion(key) {
      const match = key?.match(/\/([\d.]+)\./);
      return match ? match[1] : 'Unknown version';
    },
    getLabel(option) {
      const version = this.getVersion(option.Key);
      const message = option.Metadata?.message ? option.Metadata.message : 'No message';
      const versionDate = option.Metadata?.date || option.Metadata?.commitdate;
      const date = versionDate
        ? new Date(versionDate).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          })
        : null;

      return date
        ? `${date} - ${message} (${version})`
        : `${message} (${version})`;
    }
  }
};
</script>

<style lang="scss">
.multiselect-wrapper {
  display: grid;
  grid-template-rows: max-content max-content;
}

.multiselect__single span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}

.option-item {
  padding: 8px 12px;
  font-size: 0.85rem;
  font-weight: 400;
  color: var(--theme-on-background);
}
</style>
