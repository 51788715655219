<template>
  <div class="accordion-item" @mouseover="upHere = true" @mouseleave="upHere = false">
    <div class="accordion-item-header">
      <div class="header-buttons">
        <Button class="button active" @click="toggleExpand">
          <md-icon :name="expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
      </div>

      <div class="title">
        <div class="prefix" :title="linkPrefixText">
          {{ linkPrefixText }}
        </div>
        <span>
          <TextField
            v-if="editingTitle"
            v-model="localItem.name"
            class="title-input"
            :focus="true"
            @blur="editingTitle = false"
            @input="onTitleChange"
          />
          <span v-else @click="editingTitle = true"> {{ localItem.name }} </span>
        </span>
      </div>
      <div v-if="upHere && !isPromptStreaming" class="header-buttons">
        <div class="ordering">
          <div>
            <div v-if="!first" class="button" :disabled="isPromptStreaming" :class="{ active: !first }" title="Move Up" @click="moveUp">
              <md-icon name="menu-up" />
            </div>
          </div>
          <div>
            <div v-if="!last" class="button" :disabled="isPromptStreaming" :class="{ active: !last }" title="Mode Down" @click="moveDown">
              <md-icon name="menu-down" />
            </div>
          </div>
        </div>
        <div class="actions">
          <Button class="button" :class="{ active }" :disabled="isPromptStreaming" title="Delete link" @click="deleteLink">
            <md-icon name="delete"
          /></Button>
          <Button class="button" :class="{ active }" :disabled="isPromptStreaming" title="Execute link" @click="execute">
            <md-icon name="play"
          /></Button>
        </div>
      </div>
    </div>
    <div v-if="expanded" class="accordion-item-content">
      <div v-if="localItem.type === 'prompt'">
        <PromptBody :item="localItem" :suggestions="suggestions" :variables="allAvaliableVariables" @change="onChange" />
      </div>
      <div v-if="localItem.type === 'query'">
        <QueryBody :item="localItem" :suggestions="suggestions" :variables="allAvaliableVariables" @change="onChange" />
      </div>
      <div v-if="localItem.type === 'template'">
        <TemplateBody :item="localItem" :suggestions="suggestions" :variables="allAvaliableVariables" @change="onChange" />
      </div>
      <div v-if="localItem.type === 'form'">
        <FormBody :item="localItem" :suggestions="suggestions" :prefix="linkPrefixText" @change="onChange" />
      </div>
      <div v-if="localItem.type === 'image'">
        <ImageBody :item="localItem" @change="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon';
import PromptBody from './body/PromptBody';
import QueryBody from './body/QueryBody';
import TemplateBody from './body/TemplateBody';
import FormBody from './body/FormBody.vue';
import ImageBody from './body/ImageBody.vue';

export default {
  components: {
    PromptBody,
    QueryBody,
    TemplateBody,
    TextField,
    Button,
    MdIcon,
    FormBody,
    ImageBody
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    chain: {
      type: Array,
      required: true
    },
    context: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    indexInGroup: {
      type: Number,
      required: true
    },
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localItem: {
        ...this.item
      },
      editingTitle: false,
      expanded: false,
      upHere: false
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: s => s.prompts.sample.isRequestPending,
      active: s => !s.prompts.sample.isRequestPending,
      models: s => s.nlp.models || []
    }),
    allAvaliableVariables() {
      const allVars = [];

      this.chain
        .slice(0, this.index)
        .filter(c => c.type === 'form')
        .forEach(link => allVars.push(...link.variables));

      return allVars;
    },
    linkPrefixText() {
      return `${this.item.type.substring(0, 1).toUpperCase()}${this.indexInGroup + 1}`;
    },
    variableSuggestions() {
      if (this.index === 0) {
        return [];
      }

      return this.chain.slice(0, this.index).map(link => {
        const index = this.chain.filter(c => c.type === link.type).indexOf(link);
        return `${link.type.toUpperCase()}_${index + 1}_OUTPUT`;
      });
    },
    suggestions() {
      const suggestions = [];

      suggestions.push(...this.textVariablesSuggestions);
      suggestions.push(...this.outputVariables);

      if (this.localItem.type !== 'prompt') {
        suggestions.push(...this.contextVariables);
      }

      return suggestions;
    },
    textVariablesSuggestions() {
      return this.allAvaliableVariables
        .filter(v => v.type === 'string')
        .map(v => ({
          label: v.name,
          documentation: v.label || v.name
        }));
    },
    outputVariables() {
      if (this.index === 0) {
        return [];
      }

      return this.chain
        .slice(0, this.index)
        .filter(c => c.type !== 'form')
        .map(link => {
          const index = this.chain.filter(c => c.type === link.type).indexOf(link);
          return {
            label: `${link.type.toUpperCase()}_${index + 1}_OUTPUT`,
            documentation: `Output of ${link.type} ${index + 1}`
          };
        });
    },
    bindingSuggestions() {
      const suggestions = [];

      if (this.context.invention) {
        suggestions.push({
          label: `invention`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Invention information. Please see invention context object for more details.`
        });
      }

      return suggestions;
    },
    contextVariables() {
      if (!this.context) {
        return [];
      }

      const suggestions = [];

      if (this.context.invention) {
        suggestions.push({
          label: `invention`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Invention information. Please see invention context object for more details.`
        });
      }

      if (this.context.claims) {
        suggestions.push({
          label: `claims`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Information about document claims. Please see document context object for more details.`
        });

        suggestions.push({
          label: `claims`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Information about document claims. Please see document context object for more details.`
        });
      }

      if (this.context.specification) {
        suggestions.push({
          label: `specification`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Information about specification. Please see document context object for more details.`
        });
      }

      if (this.context.properties) {
        suggestions.push({
          label: `properties`,
          kind: monaco.languages.CompletionItemKind.Class,
          documentation: `Information about document properties. Please see document context object for more details.`
        });
      }

      return suggestions;
    }
  },
  methods: {
    getLinkModel(candidate) {
      const models = this.models;
      const model = models.find(m => m.id == candidate.model);
      if (model) {
        return model;
      }

      for (const md of models) {
        if (!md.config) {
          continue;
        }
        var config = JSON.parse(md.config);
        if (config.deployment == candidate.model) {
          return md;
        }
      }

      return null;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    attachmentChecked(name, checked) {
      if (checked) {
        this.localItem.attachments.push(name);
      } else {
        var index = this.localItem.attachments.indexOf(name);
        this.localItem.attachments.splice(index, 1);
      }
    },
    onTitleChange() {
      this.onChange(this.localItem);
    },
    onChange(e) {
      const { name, ...rest } = e;
      this.localItem = {
        name: this.localItem.name,
        ...rest
      };
      this.$emit('change', { ...this.localItem });
    },
    deleteLink() {
      this.$emit('delete');
    },
    execute() {
      this.$emit('execute');
    },
    moveUp() {
      this.$emit('up');
    },
    moveDown() {
      this.$emit('down');
    },
    toggleAttach() {
      this.attachmentsExpanded = !this.attachmentsExpanded;
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-item {
  .accordion-item-header {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    align-content: center;
    min-height: 50px;
    height: 100%;
    background-color: var(--theme-surface);
    border-bottom: 1px solid var(--theme-on-surface);
    padding: 0 10px;

    .header-buttons {
      display: flex;
      align-items: center;

      .actions {
        display: flex;
        padding-right: 5px;
      }

      .ordering {
        display: grid;
        padding: 0 10px;
        grid-template-rows: 1fr 1fr;

        i {
          gap: 0;
          padding: 0;
          margin: 0;
          height: 18px;
          width: 18px;
          font-size: 18px;
        }

        i:first-child {
          justify-self: flex-start;
        }

        i:last-child {
          justify-self: flex-end;
        }
      }
    }
  }

  .title {
    display: grid;
    gap: 5px;
    align-items: center;
    grid-template-columns: max-content 1fr;

    .prefix {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border: 1px solid;
      border-radius: 3px;
      font-family: 'Robotic';
      font-weight: 800;
      padding: 2px;
      user-select: none;
    }

    .title-input {
      grid-gap: 0;
    }

    label {
      font-style: normal;
    }
  }

  .button {
    padding: 0px;
  }

  .active {
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }
  }

  .accordion-item-content {
    padding: 10px;

    .settings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    .content {
      padding: 10px;

      .template-content {
        margin: 10px 0;
        display: grid;
        gap: 10px;

        .label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }

        .editor {
          height: 200px;
        }

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;

        .attachments {
          position: relative;

          .attachemnts-pop-up {
            display: none;
            gap: 5px;
            padding: 10px;
            border: 1px solid white;
            background-color: var(--theme-surface);

            .attachment {
              display: flex;
              align-content: flex-start;
              gap: 5px;
              font-size: 0.85rem;
              padding: 5px;
              padding-left: 0;
              min-width: 150px;
            }
          }

          .expanded {
            display: flex;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
